import { defineStore } from "pinia";
import { Configuration, GatewayApi, Gateway } from "@/generated/";
import { ref, inject } from "vue";
import { API_URL } from "@/config";
import { handleError } from "@/utils/errors";

export const useGatewayStore = defineStore("gateway", () => {
  const apiConfig: Configuration = {
    basePath: API_URL,
    accessToken: window.localStorage.getItem("auth")?.toString(),
    isJsonMime: function (mime: string): boolean {
      throw new Error("Function not implemented.");
    }
  };
  const sig: any = inject("signalr");
  const api = new GatewayApi(apiConfig);
  const gateways = ref([] as Gateway[]);
  const loading = ref(false);

  async function connectToHub() {
    sig.on("event-gateway-heatbeat", (gateway: string, lastHeartbeat: string) => {
      gateways.value.find(x => x.gatewayId == gateway)!.lastHeartbeat = lastHeartbeat;
    })
  }

  async function getGateways(page:number, perPage:number, id:string) {
    loading.value = true;
    return await api.gatewayGetPage(id, page, perPage).then((response) => {
      if (response.data.success) {
        gateways.value = response.data.content!;
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }


  return {
    getGateways,
    connectToHub,
    gateways,
    loading
  }
})
