import { defineStore } from "pinia";
import { Configuration, NodeApi, NodeWithCurrentData, NodeEvent } from "@/generated/";
import { ref } from "vue";
import { API_URL } from "@/config";
import { handleError } from "@/utils/errors";

export const useNodeStore = defineStore("node", () => {
  const apiConfig: Configuration = {
    basePath: API_URL,
    accessToken: window.localStorage.getItem("auth")?.toString(),
    isJsonMime: function (mime: string): boolean {
      return true;
    }
  };
  const api = new NodeApi(apiConfig);
  const nodes = ref([] as NodeWithCurrentData[]);
  const node = ref({} as NodeWithCurrentData);
  const events = ref([] as NodeEvent[]);
  const loading = ref(false);

  async function getNode(id:string) {
    loading.value = true;
    return await api.nodeGetById(id).then((response) => {
      if (response.data.success) {
        node.value = response.data.content!;
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function getNodes(id:string) {
    loading.value = true;
    return await api.getAllById(id).then((response) => {
      if (response.data.success) {
        nodes.value = response.data.content!;
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }
  async function getLastNodeEvent(id:string) {
    loading.value = true;
    return await api.nodeGetLastEventById(id).then((response) => {
      if (response.data.success) {
        console.log(response.data.content)
        events.value = [response.data.content!];
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function getNodeEvents(id:string, page:number, size:number) {
    loading.value = true;
    events.value = [];
    return await api.nodeGetPage(id, page, size).then((response) => {
      if (response.data.success) {
        events.value = response.data.content!;
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function updateNodeConfig(nodeId:string, config:string) {
    loading.value = true;
    return await api.nodeUpdateConfig({nodeId, config}).then((response) => {
      if (response.data.success) {
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function sendCommand(nodeId:string, command:string) {
    loading.value = true;
    events.value = [];
    return await api.nodeNodeCommand(nodeId, command).then((response) => {
      if (response.data.success) {
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }


  return {
    getNode,
    getNodes,
    getNodeEvents,
    getLastNodeEvent,
    updateNodeConfig,
    sendCommand,
    node,
    nodes,
    events,
    loading
  }
})
